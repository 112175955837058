import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import {
  ContentfulPage,
  ContentfulMedia,
  ContentfulText,
  ContentfulList,
} from '../../../types/graphql-types'
import SEO from '../../components/SEO'
import LayoutBlank from '../../components/Layout/Blank'
import Footer from '../../components/Footer/Email'

interface Props {
  data: {
    contentfulPage: ContentfulPage
  }
}

export const query = graphql`
  query LivelyAppAccountWithContractWelcome {
    contentfulPage(
      slug: { eq: "/emails/lively-app-account-with-contract-welcome/" }
      externalName: { eq: "Email Web View" }
    ) {
      externalName
      slug
      seoTitle
      seoDescription
      noindex
      type
      gtmPageType
      references {
        ... on ContentfulMedia {
          __typename
          id
          name
          externalName
          altText
          media {
            file {
              url
            }
          }
        }
        ... on ContentfulText {
          __typename
          id
          name
          externalName
          richText {
            raw
            references {
              ... on ContentfulGatsbyComponent {
                __typename
                contentful_id
                name
                externalName
                id
                references {
                  ... on ContentfulText {
                    id
                    __typename
                    contentful_id
                    externalName
                    shortSimpleText
                    longSimpleText {
                      childMarkdownRemark {
                        html
                      }
                    }
                  }
                }
              }
            }
          }
          longSimpleText {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on ContentfulList {
          __typename
          id
          name
          externalName
          items {
            ... on ContentfulFeatureContent {
              __typename
              id
              name
              externalName
              references {
                ... on ContentfulText {
                  __typename
                  id
                  name
                  externalName
                  longSimpleText {
                    childMarkdownRemark {
                      html
                    }
                  }
                  richText {
                    raw
                    references {
                      __typename
                      ... on ContentfulAsset {
                        id
                        file {
                          url
                        }
                      }
                    }
                  }
                }
                ... on ContentfulMedia {
                  __typename
                  id
                  name
                  externalName
                  altText
                  media {
                    file {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const ContentWrapper = styled.article`
  ${tw`my-0 mx-auto`}
  width: 600px;

  .hero {
    ${tw`mb-4`}
  }

  .intro {
    ${tw`my-4`}

    h2 {
      ${tw`text-2xl mb-2 text-center`}
    }
    p {
      ${tw`text-left leading-7`}
    }
  }

  .steps {
    ol {
      ${tw``}
    }

    ol > li {
      ${tw`p-8 flex mb-4`}

      img {
        height: 151px;
        width: 151px;
      }

      h3 {
        ${tw`text-2xl text-bby-blue-700 leading-none mb-4`}

        img {
          ${tw`w-11 h-11 inline mr-4`}
        }
      }

      p > img {
        ${tw`h-11 mt-4`}
        width: 130px;
      }

      p > small {
        ${tw`leading-none`}
      }

      &:nth-of-type(odd) {
        background: rgba(216, 216, 216, 0.2);
      }
    }
  }

  .help-cta,
  .billing-info {
    p {
      ${tw`leading-7`}

      a {
        ${tw`text-bby-blue-700 font-semibold underline`}
      }
    }
  }

  .billing-info {
    ${tw`p-8`}
    background: rgba(216, 216, 216, 0.2);

    h2 {
      ${tw`text-2xl mb-4`}
    }
  }
`

const LivelyAppAccountWithContractWelcome: React.FC<Props> = ({
  data,
}: Props) => {
  return (
    <>
      <LayoutBlank>
        <SEO
          title={data.contentfulPage.seoTitle as string}
          description={data.contentfulPage.seoDescription as string}
          noIndex={true}
        />
        <ContentWrapper>
          {data.contentfulPage.references?.map(content => {
            const media = content as ContentfulMedia
            const copy = content as ContentfulText
            const list = content as ContentfulList

            if (media.externalName === 'Brand Logo') {
              return (
                <header key={media.id}>
                  <img
                    className="h-auto"
                    src={media.media?.file?.url as string}
                    alt={media.altText as string}
                  />
                </header>
              )
            }
            if (media.externalName === 'Email Hero') {
              return (
                <section key={media.id} className="hero">
                  <h1>
                    <img
                      className="h-auto"
                      src={media.media?.file?.url as string}
                      alt={media.altText as string}
                    />
                  </h1>
                </section>
              )
            }
            if (copy.externalName === 'Intro') {
              return (
                <section key={copy.id} className="intro">
                  <div
                    key={copy.id}
                    dangerouslySetInnerHTML={{
                      __html: copy.longSimpleText?.childMarkdownRemark
                        ?.html as string,
                    }}
                  ></div>
                </section>
              )
            }
            if (list.externalName === 'List') {
              return (
                <section className="steps" key={list.id}>
                  <ol key={list.id}>
                    {list.items?.map(step => {
                      const text = step?.references[0]
                      const image = step?.references[1]
                      return (
                        <li key={step?.id}>
                          <div
                            key={text.id}
                            dangerouslySetInnerHTML={{
                              __html: text.longSimpleText?.childMarkdownRemark
                                ?.html as string,
                            }}
                          ></div>
                          <img
                            src={image.media?.file?.url as string}
                            alt={image.altText as string}
                          />
                        </li>
                      )
                    })}
                  </ol>
                </section>
              )
            }
            if (copy.externalName === 'CTA') {
              return (
                <section className="help-cta" key={copy.id}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: copy.longSimpleText?.childMarkdownRemark
                        ?.html as string,
                    }}
                  ></div>
                </section>
              )
            }
            if (copy.externalName === 'Billing Info') {
              return (
                <section className="billing-info" key={copy.id}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: copy.longSimpleText?.childMarkdownRemark
                        ?.html as string,
                    }}
                  ></div>
                </section>
              )
            }
          })}
        </ContentWrapper>
      </LayoutBlank>
      {data.contentfulPage.references?.map(content => {
        const footerCopy = content as ContentfulText

        if (footerCopy.externalName === 'Copyright') {
          const copyrightText = (
            <div
              className="copyright mb-0"
              key={footerCopy.id}
              dangerouslySetInnerHTML={{
                __html: footerCopy.longSimpleText?.childMarkdownRemark
                  ?.html as string,
              }}
            ></div>
          )
          return <Footer key={footerCopy.id} copyright={copyrightText} />
        }
      })}
    </>
  )
}

export default LivelyAppAccountWithContractWelcome
